/*import "core-js/stable/object/assign";
import "core-js/stable/object/keys";
import "core-js/stable/object/values";
import "core-js/stable/array/includes";
import "core-js/stable/array/is-array";
import "core-js/stable/array/find";
import "core-js/stable/array/values";
import "core-js/stable/string/includes";
import "core-js/stable/promise";*/
import "core-js/stable";
import "element-closest/browser";

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
